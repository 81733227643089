import ical from 'ical.js';
import moment from 'moment';

document.querySelector('#cal-file').addEventListener('change', handleFileChange);
const copyBtn = document.querySelector('#copy-btn');
copyBtn.addEventListener('click', handleCopy);
copyBtn.style.display = 'none';

let GLOBAL_tableData = null;

function parseIcs(shiftData) {
    let parsedIcs = [];

    for (let i = 1; i < shiftData.length; i++) {
        const shift = shiftData[i][1];
        const startTime = moment(shift.find(arr => arr[0] === 'dtstart')[3]);
        const endTime = moment(shift.find(arr => arr[0] === 'dtend')[3]);
        const shiftLength = moment.duration(endTime - startTime);    

        parsedIcs.push({
            startTime,
            endTime,
            shiftLength,
        });
    }

    parsedIcs.sort((a, b) => a.startTime - b.startTime);

    return parsedIcs;
}

function generateOutput(parsedIcs) {
    const shiftTable = document.querySelector('#shifts');

    for (let i = 0; i < parsedIcs.length; i++) {
        const { startTime, shiftLength } = parsedIcs[i];
        const tr = document.createElement('tr');
        let tds = [];
        
        for (let j = 0; j < 3; j++) {
            const td = document.createElement('td')
            tds.push(td);
            tr.appendChild(td);
        }
    
        tds[0].innerText = startTime.format('dddd');
        tds[1].innerText = startTime.format('DD/MM/YYYY');
        tds[2].innerText = Math.round(shiftLength.asHours() * 1000) / 1000;
    
        shiftTable.appendChild(tr);
    }

    return shiftTable.innerText;
}

async function handleFileChange(e) {
    const file = e.target.files[0];
    const fText = await file.text();
    const calData = ical.parse(fText);
    const shiftData = calData[2];
    console.log(shiftData);

    const parsedIcs = parseIcs(shiftData);
    const output = generateOutput(parsedIcs);
    GLOBAL_tableData = output;
    copyBtn.style.display = 'block';
}

async function handleCopy() {
    await navigator.clipboard.writeText(GLOBAL_tableData);
    alert('done')
}
